import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/booking.module.css";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
} from "pure-react-carousel";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { Tabs } from "@material-ui/core";
import { vehicleSpecsImageURL } from "../../store/constants/baseUrl";
import { useNavigate } from "react-router-dom";
import "pure-react-carousel/dist/react-carousel.es.css";
import { CustomTooltip } from "../Tooltip/tooltip";
import Modal from "@mui/material/Modal";
import css2 from "../../style/vehicleSpecs.module.css";
import { FaCircle } from "react-icons/fa";
import Box from "@mui/material/Box";

function useWindowSize() {
  const [size, setSize] = useState([]);
  useEffect(() => {
    try {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.addEventListener("resize", handleResize);
      };
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);
  return size;
}

const BulletPoints = (text, title) => {
  return (
    <div className={css2?.BulletMainDiv}>
      <FaCircle className={css2?.bulletCircle} />
      <span className={css2?.bulletText}>{title + text}</span>
    </div>
  );
};

const SliderComponent = ({
  vehicles,
  vehicleTypeId,
  handleUpdateBookingObject,
  type,
}) => {
  // Menu state
  const [menuState, setmenuState] = useState(false);
  const [popupDetails, setPopupDetails] = useState({});
  const [allowPopupDisappear, setAllowPopupDisappear] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  // getting the dimentions
  const [height, width] = useWindowSize();
  // getting history for routing
  const history = useNavigate();
  // find indexof current booking
  const getCurrentVehicleIndex = () => {
    const index = vehicles?.findIndex(
      (vehicle) => vehicle.id === vehicleTypeId
    );
    return index;
  };
  // returns bg color for button next
  const handleReturnBG = (active, inactive) => {
    try {
      if (getCurrentVehicleIndex() === vehicles?.length - 1) {
        return inactive;
      } else {
        return active;
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  // returns bg color for button back
  const handleReturnBG2 = (active, inactive) => {
    try {
      if (getCurrentVehicleIndex() === 0) {
        return inactive;
      } else {
        return active;
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  // Scroll the tabs
  const scrollTab = (index) => {
    const selectedVehicle = vehicles[index];
    if (selectedVehicle.id != vehicleTypeId) {
      handleUpdateBookingObject("vehicle", selectedVehicle, "13.1");
      handleUpdateBookingObject("vehicleTypeId", selectedVehicle.id, "13.2");
    }
    let len = vehicles.length;
    if (len > 1) {
      const tab = document.getElementById("vehicle-slider-2");
      tab.scrollLeft = (index - len / 2 + 1) * 150;
    }
  };

  const handleShowHidePopup = (details) => {
    const showTimeout = window.setTimeout(() => {
      setShowPopup(true);
    }, 1000);
    const hideTimeout = window.setTimeout(() => {
      setShowPopup(false);
    }, 5000);
    if (details) {
      setPopupDetails(details);
      clearTimeout(hideTimeout);
    } else {
      clearTimeout(showTimeout);
    }
  };

  const handleHidePopup = () => {
    setPopupDetails({});
    setShowPopup(false);
  };

  return (
    <div className={css2?.sliderMainDiv}>
      <Modal open={showPopup} onClose={handleHidePopup}>
        <Box
          sx={{ bgcolor: "background.paper" }}
          className={css.vehicleConponentModal}
        >
          <div className={css2?.rMd} style={{ width: "100%" }}>
            <div className={css2?.rImgMd}>
              <img
                src={`${vehicleSpecsImageURL}${popupDetails.pictureUrl}`}
                alt={popupDetails?.title + " image"}
                className={css2?.rImg}
              />
            </div>
            <div className={css2?.rContent}>
              <div className={css2?.rLContent}>
                <div className={css2?.contentHeading}>
                  {popupDetails.title} Info:
                </div>
                {BulletPoints(
                  ` ${popupDetails.length}ft x ${popupDetails.width}ft x ${popupDetails.height}ft`,
                  "Cargo space (LxWxH):"
                )}
                {popupDetails?.cargoSpace?.maxLoad
                  ? BulletPoints(
                      popupDetails.cargoSpace.maxLoad,
                      "Max load (lbs) "
                    )
                  : ""}
              </div>
              <div className={css2?.rRContent} style={{ width: "100%" }}>
                {popupDetails?.recommendedUse?.length ? (
                  <>
                    <div className={css2?.contentHeading}>Recommended use</div>
                    {popupDetails.recommendedUse.map((use) =>
                      BulletPoints(use?.useLine, "")
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className={css2?.vehicleSpecsDisclaimer}>
                <i>
                  All measurements are approximate. Cargo space varies based on
                  make and model of vehicle. We categorize our vehicles based on
                  industry standard dimensions but these may vary slightly
                  according to the vehicle that gets matched to your order.
                </i>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <div className={css?.Level1Heading}>
        Select your vehicle {`(${type})`}
      </div>
      <div className={css?.newVehicleSlider} id="vehicle-slider-2">
        {vehicles?.map((vehicle, index) => (
          <div
            className={css?.vehicleSliderCard2}
            style={{
              borderColor:
                vehicle.id === vehicleTypeId
                  ? "var(--secondary)"
                  : "transparent",
            }}
            // onClick={() => history(`/vehicle/${vehicle?.id}`)}
            onClick={() => scrollTab(index)}
            title={`Dimensions:${vehicle?.length}ft X ${vehicle?.width}ft X ${vehicle?.height}ft`}
          >
            <img
              src="images/info2.svg"
              className={css?.vehicleTooltip}
              alt=""
              onMouseEnter={() => handleShowHidePopup(vehicle)}
              onMouseLeave={() => handleShowHidePopup()}
            />
            <img
              src={vehicleSpecsImageURL + vehicle?.pictureUrl}
              alt={vehicles.title}
              style={{ width: 170 }}
            />
            <div
              className={css?.vehiclesName3}
              style={{
                color:
                  vehicle.id === vehicleTypeId
                    ? "var(--secondary)"
                    : "var(--primary)",
              }}
            >
              {vehicle?.title}
            </div>
            {/* <div className={css?.vehiclesDimentions2}>
              {vehicle?.length +
                "ft X " +
                vehicle?.width +
                "ft X " +
                vehicle?.height +
                "ft"}
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SliderComponent;
