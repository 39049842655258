import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGOUT,
} from "../constants/LoginConstants";
import axios from "axios";
import * as Sentry from "@sentry/react";
import baseUrl from "../constants/baseUrl";
import { updateBooking, postBooking } from "./bookingActions";
import { generateBookingLogs } from "../../Utilities/general";

const handleUpdateBookingObject = (_, booking, type) => {
  const bookingTypeFromLS = localStorage.getItem("loginBookingType");
  generateBookingLogs(
    null,
    booking,
    type,
    `wemoovez${bookingTypeFromLS}Object`
  );
  localStorage.setItem(
    `wemoovez${bookingTypeFromLS}Object`,
    JSON.stringify(booking)
  );
  localStorage.removeItem("redirectToBooking");
  localStorage.removeItem("loginBooking");
  localStorage.removeItem("loginBookingType");
};
const handlePostBooking = async (userId, history) => {
  const bookingTypeFromLS = localStorage.getItem("loginBookingType");
  if (bookingTypeFromLS) {
    let temp = localStorage.getItem(`loginBooking`);
    if (temp) {
      temp = JSON.parse(temp);
      temp = { ...temp, tripStatus: "Confirmation" };
      if (temp.id)
        await updateBooking(
          temp?.id,
          {
            ...temp,
            userId,
          },
          handleUpdateBookingObject,
          () => {}
        );
      else
        await postBooking(
          {
            ...temp,
            userId,
            IsDeliverNow: temp?.deliveryDate ? false : true,
          },
          handleUpdateBookingObject,
          () => {}
        );
    }
    history(`/${bookingTypeFromLS}`);
    return;
  }
  window.location.href = "https://moovez.ca/";
};
// login action
// this action is called in wemoovezLogin page
// it redirects to home page
export const login =
  (loginData, setError, history, setLoader) => async (dispatch) => {
    try {
      dispatch({
        type: LOGIN_REQUEST,
      });
      const { data } = await axios.post(`${baseUrl}Auth/login`, loginData);
      if (data?.data?.email) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: data?.data,
        });
        let temp1 = JSON.parse(
          localStorage.getItem("redirectToSummary") ?? "{}"
        );
        const temp2 = localStorage.getItem("redirectToBooking");
        setLoader(false);
        if (temp1?.userId) {
          localStorage.removeItem("redirectToSummary");
          if (parseInt(temp1?.userId) !== data?.data?.id) {
            history(`/`);
          } else {
            history(`/orderSummary/${temp1.userId}/${temp1.bookingId}`);
          }
        } else if (temp2 && temp2 !== "undefined") {
          handlePostBooking(data?.data?.id, history);
        } else {
          history(`/`);
        }
      } else {
        history(`/confirmEmail/${loginData?.email}/${data?.data?.phone}`);
      }
      localStorage.removeItem("signupObj");
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ??
        "Login failed, please try again later!";
      dispatch({
        type: LOGIN_FAIL,
        payload: errorMessage,
      });
      setLoader(false);
      setError(errorMessage);
    }
  };

// signup action
// this action is called in Registerwemoovez page
// it redirects to confirm email page
export const signup = (loginData, setError, history) => async (dispatch) => {
  console.log("signup cred", loginData);
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}Auth/signup`, loginData);
    console.log("signup data from api", data);
    history(`/confirmEmail/${data?.email}/${data?.phone}`);
    localStorage.removeItem("signupObj");
  } catch (error) {
    const errorMessage =
      error?.response?.data ?? "Signup failed, please try again later!";
    dispatch({
      type: LOGIN_FAIL,
      payload: errorMessage,
    });
    setError(errorMessage);
  }
};

export const logout = (history, route) => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT,
    });
    history(route ? route : "/");
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
    dispatch({
      type: LOGIN_FAIL,
      payload: "Content Update Failed,Please try again!",
    });
  }
};

// confirm email action
// this action is called i confirm email page
export const ConfirmEmailAction =
  (email, code, history) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}Auth/confirmEmail?code=${code}&email=${email}`
      );

      console.log("response from confirmation", data);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: data,
      });
      let temp = localStorage.getItem("redirectToBooking");
      if (temp && temp !== "undefined") {
        handlePostBooking(data?.data?.id, history);
      } else {
        history(`/`);
      }
      if (data?.isFromReferral) {
        alert(
          "Congratulations, you have earned $5. You see your credit in user profile section."
        );
      }
    } catch (error) {
      console.log("verification failed", error);
      dispatch({
        type: LOGIN_FAIL,
        payload:
          "That code does not seem to match, please try entering it again.",
      });
    }
  };

//updateUser
// update user profile action
// this action is called in userProfile page
export const updateUser = (userData) => async (dispatch) => {
  console.log("new user cred", userData);
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}Auth/login`, userData);
    console.log("user data from api", data);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
    dispatch({
      type: LOGIN_FAIL,
      payload: "update Failed,Please try again!",
    });
  }
};

// login action
// this action is called in Login
// it user's profile is present in localstorage, log user in
export const populateUser = (userCredentials, history) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: userCredentials,
    });
    const path = window.location.pathname;
    if (path === "/Login" || path === "/Signup")
      window.location.href = "https://moovez.ca/";
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};
