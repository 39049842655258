import React, { useState } from "react";
import css from "../../style/booking.module.css";

const CouponCode = ({ coupon, handleUpdateBookingObject }) => {
  const [myCoupon, setMyCoupon] = useState(coupon);

  const applied = () => coupon && coupon === myCoupon;

  const handleApplyCoupon = () => {
    let newCoupon = "";
    if (applied()) setMyCoupon(newCoupon);
    else newCoupon = myCoupon;
    handleUpdateBookingObject("coupon", newCoupon);
  };

  return (
    <div className={css?.couponMd}>
      <input
        value={myCoupon}
        className={css?.couponText}
        placeholder="Coupon or discount code"
        onChange={(e) => setMyCoupon(e.target.value)}
      />
      <div
        className={applied() ? css?.couponBrnRemove : css?.couponBrn}
        onClick={handleApplyCoupon}
      >
        {applied() ? "Remove" : "Apply"}
      </div>
    </div>
  );
};

export default CouponCode;
