import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
// IMPORTS
// FOR
// WEB
// Info
import Home from "./pages/Home";
import BecomeAMoover from "./pages/BecomeAMoover";
import HowItWorks from "./pages/HowItWorks";
import Help from "./pages/Help";
import Press from "./pages/Press";
import Earnings from "./pages/Earnings";
import OurStoryPage from "./pages/OurStory";
import FAQsPage from "./pages/FAQs";
import ContactUsPage from "./pages/ContactUs";
import PrivacyPage from "./pages/Privacy";
import TermsPage from "./pages/Terms";
import FeedBackPage from "./pages/FeedBack";
import VehicleInfo from "./pages/VehicleInfo";
import EarningsPage from "./pages/Earnings";
import BusinessDeliveryInfoPage from "./pages/BusinessDeliveryInfo";
import MoverInfo from "./pages/MoverInfo";
// Booking
import MyTripsPage from "./pages/MyTrips";
import SingleTripPage from "./pages/SingleTrip";
import BookingPage from "./pages/BookingPage";
import ThankYouPage from "./pages/Thankyou";
import SignupBusinessPage from "./pages/SignupBusiness";
import OrderSummary from "./pages/OrderSummary";
import BookingSummary from "./pages/BookingSummary";
import RejectSummary from "./pages/RejectSummary";
import MoveCompleted from "./pages/MoveCompleted";
import RatingPage from "./pages/RatingPage";
// Booking Types
import DeliveryPage from "./pages/Delivery";
import MovingPage from "./pages/Moving";
import MoversPage from "./pages/Movers";
import BusinessPage from "./pages/Business";
// Account
import LoginPage from "./pages/Login";
import SignupPage from "./pages/Signup";
import UserProfilePage from "./pages/UserProfile";
import ConfirmEmailPage from "./pages/ConfirmEmail";
import ResetPasswordPage from "./pages/ResetPassword";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ReferralSignupPage from "./pages/ReferralSignup";
import DeliveryInfoPage from "./pages/DeliveryInfo";
// Blogs
import BlogList from "./pages/BlogList";
import BlogDetails from "./pages/BlogDetails";

function App() {
  if (process.env.REACT_APP_MODE !== "development") console.log = () => {};
  return (
    <Routes>
      {/* Blog */}
      <Route path="/blog" element={<BlogList />} />
      <Route path="/blog/:title" element={<BlogDetails />} />
      {/* info */}
      <Route path="/home" element={<Home />} />
      <Route path="/BecomeAMoover" element={<BecomeAMoover />} />
      <Route path="/HowItWorks" element={<HowItWorks />} />
      <Route path="/Help" element={<Help />} />
      <Route path="/Press" element={<Press />} />
      <Route path="/Earnings" element={<Earnings />} />
      <Route path="/OurStory" element={<OurStoryPage />} />
      <Route path="/FAQs" element={<FAQsPage />} />
      <Route path="/ContactUs" element={<ContactUsPage />} />
      <Route path="/Privacy" element={<PrivacyPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/earnings" element={<EarningsPage />} />
      <Route path="/feedback" element={<FeedBackPage />} />
      <Route path="/deliveryInfo" element={<DeliveryInfoPage />} />
      <Route path="/businessInfo" element={<BusinessDeliveryInfoPage />} />
      <Route path="/moverInfo" element={<MoverInfo />} />
      <Route path="/vehicle/:vehicleId" element={<VehicleInfo />} />
      {/* booking */}
      <Route path="/MyTrips/:userId" element={<MyTripsPage />} />
      <Route path="/booking" element={<BookingPage />} />
      <Route path="/thankyou" element={<ThankYouPage />} />
      <Route path="/business" element={<SignupBusinessPage />} />
      <Route
        path="/SingleTrip/:userId/:bookingId"
        element={<SingleTripPage />}
      />
      <Route
        path="/moveCompleted/:userId/:bookingId"
        element={<MoveCompleted />}
      />
      <Route path="/rejectSummary/:userEmail" element={<RejectSummary />} />
      <Route path="/rating/:userId/:bookingId" element={<RatingPage />} />
      <Route
        path="/bookingSummary/:userId/:bookingId"
        element={<BookingSummary />}
      />
      <Route
        path="/orderSummary/:userId/:bookingId"
        element={<OrderSummary />}
      />
      {/* Booking Types */}
      <Route path="/" element={<DeliveryPage />} />
      <Route path="/moving" element={<MovingPage />} />
      <Route path="/movers" element={<MoversPage />} />
      <Route path="/businessBooking" element={<BusinessPage />} />
      {/* Account */}
      <Route path="/Login" element={<LoginPage />} />
      <Route path="/Signup" element={<SignupPage />} />
      <Route path="/UserProfile/:email" element={<UserProfilePage />} />
      <Route
        path="/ConfirmEmail/:email/:phone"
        element={<ConfirmEmailPage />}
      />
      <Route path="/ResetPassword/:email" element={<ResetPasswordPage />} />
      <Route path="/ForgotPassword" element={<ForgotPasswordPage />} />
      <Route path="/RegisterMoovez/:code" element={<ReferralSignupPage />} />
      <Route path="*" element={<Navigate to="/" replace={true} />} />
    </Routes>
  );
}
export default App;
