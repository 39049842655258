import React, { useState, useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/actions/loginActions";
import { useNavigate, useLocation } from "react-router";
import css from "../../style/header.module.css";
import { Button } from "@mui/material";
import { List as ListIcon } from "@mui/icons-material";
import { profileImageURL } from "../../store/constants/baseUrl";
import { Person } from "@mui/icons-material";

// get screen width
function useWindowSize() {
  const [size, setSize] = useState([]);
  useEffect(() => {
    try {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.addEventListener("resize", handleResize);
      };
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);
  return size;
}

function MarketingHeader() {
  // dimensions
  const [height, width] = useWindowSize();
  const [userMenuToggleState, setuserMenuToggleState] = React.useState(null);
  const [makePersonGreen, setMakePersonGreen] = React.useState(false);
  // dispatch actions
  const dispatch = useDispatch();
  // getting user info
  const { loginInfo } = useSelector((state) => {
    return state.loginReducer;
  });

  //history for routing
  const history = useNavigate();
  const location = useLocation();

  //function to open user menu
  const handleOpenUserMenu = (event) => {
    setuserMenuToggleState(event.currentTarget);
  };
  // Menu for signup
  const [openSignupMenu, setopenSignupMenu] = useState(false);
  // showDrawer
  const [showDrawer, setshowDrawer] = useState(false);
  const returnColor = (url) => {
    try {
      if (location.pathname === url) return "var(--secondary)";
      return "white";
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const menuRef = useRef();
  const mobileMenuRef = useRef();

  useEffect(() => {
    const closeSignupMenuEvent = (e) => {
      if (!menuRef?.current?.contains(e.target)) {
        setopenSignupMenu(false);
      }
    };
    document.addEventListener("click", closeSignupMenuEvent);

    return () => {
      document.addEventListener("click", closeSignupMenuEvent);
    };
  }, []);

  useEffect(() => {
    const closeMobileMenuEvent = (e) => {
      if (!mobileMenuRef?.current?.contains(e.target)) {
        setshowDrawer(false);
      }
    };
    document.addEventListener("click", closeMobileMenuEvent);

    return () => {
      document.addEventListener("click", closeMobileMenuEvent);
    };
  }, []);

  return (
    <div className={css?.headerMainDiv}>
      <div
        className={css?.wemoovezLogo}
        onClick={() => {
          window.location.href = "https://moovez.ca/";
        }}
      >
        <img
          src={"/images/logo.svg"}
          alt="wemoovez Logo"
          title="wemoovez Logo"
          className={css?.wemoovezLogo}
        />
      </div>
      {width > 900 && (
        <>
          <div className={css?.centerOptions}>
            <div className={css?.headerOptionsDiv}>
              <span
                className={css?.headerOptionstext}
                style={{ color: returnColor("/") }}
                onClick={() => {
                  history({
                    pathname: `/`,
                  });
                }}
              >
                Schedule pickup
              </span>
            </div>
            <div className={css?.headerOptionsDiv}>
              <span
                className={css?.headerOptionstext}
                style={{ color: returnColor("/BecomeAMoover") }}
                onClick={() => {
                  history("/BecomeAMoover");
                }}
              >
                Become a moover
              </span>
            </div>
            <div className={css?.headerOptionsDiv}>
              <span
                className={css?.headerOptionstext}
                style={{ color: returnColor("/HowItWorks") }}
                onClick={() => {
                  history("/HowItWorks");
                }}
              >
                How it works
              </span>
            </div>
          </div>
        </>
      )}
      {!loginInfo ? (
        <div className={css?.logoutButtons}>
          <span
            className={css?.loginButton}
            onClick={() => {
              history("/Login");
            }}
            onMouseEnter={() => setMakePersonGreen(true)}
            onMouseLeave={() => setMakePersonGreen(false)}
            title="Log in"
          >
            <img
              src={`images/user${makePersonGreen ? "Green" : ""}.svg`}
              alt=""
            />
            {width > 900 && <>Log in</>}
          </span>
          <span
            ref={menuRef}
            className={css?.signupButton}
            onClick={() => {
              setopenSignupMenu(!openSignupMenu);
            }}
            title="Sign up"
          >
            Sign up
          </span>
          <div
            className={css?.signupDrawer}
            style={{ display: openSignupMenu ? "flex" : "none" }}
          >
            <div
              style={{ borderBottom: "1.5px solid var(--primary)" }}
              className={css?.signupDrawerOption}
              onClick={() => history("/Signup")}
            >
              Create a user account
            </div>
            <div
              className={css?.signupDrawerOption}
              onClick={() => {
                window.location.href =
                  "https://moversignup.moovez.ca/Driver/MooverSignUp";
              }}
            >
              Become a moover
            </div>
          </div>

          {width < 901 && (
            <>
              <Button
                ref={mobileMenuRef}
                onClick={() => setshowDrawer(!showDrawer)}
                className={css?.drawerBtn}
              >
                <ListIcon style={{ color: "white" }} />
              </Button>
            </>
          )}
        </div>
      ) : (
        <div className={css?.logoutButtons}>
          {loginInfo?.firstName + " " + loginInfo?.lastName}

          <p
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleOpenUserMenu}
            className={css?.Profile}
          >
            {loginInfo?.profileDisplayImageUrl === null ? (
              <Person style={{ height: 50 }} />
            ) : (
              <img
                src={`${loginInfo?.profileDisplayImageUrl}`}
                style={{ borderRadius: "50%" }}
                alt="Logo"
                className={css?.Profile}
              />
            )}
          </p>
          <div className={css?.dropdown}>
            <div class={css?.dropdownContent}>
              <div className={css?.dropdownSection}>
                <div
                  className={css?.dropdownOption}
                  onClick={() => history(`/userProfile/${loginInfo?.email}`)}
                >
                  <i class="fas fa-user"></i>
                  <span className={css?.dropdownOptionText}>Profile</span>
                </div>
              </div>
              <div className={css?.dropdownSection}>
                <div
                  className={css?.dropdownOption}
                  onClick={() => history(`/myTrips/${loginInfo?.id}`)}
                >
                  <i class="fas fa-truck-moving"></i>
                  <span className={css?.dropdownOptionText}>My Trips</span>
                </div>
              </div>
              <div className={css?.dropdownSection}>
                <div
                  className={css?.dropdownOption}
                  onClick={() => history(`/resetPassword/${loginInfo?.email}`)}
                >
                  <i class="fas fa-unlock-alt"></i>
                  <span className={css?.dropdownOptionText}>
                    Change Password
                  </span>
                </div>
              </div>
              <div className={css?.dropdownSection}>
                <div
                  className={css?.dropdownOption}
                  style={{ borderBottom: "none" }}
                >
                  <span
                    className={css?.dropdownOptionText}
                    onClick={() => dispatch(logout(history))}
                  >
                    Log out
                  </span>
                </div>
              </div>
            </div>
          </div>

          {width < 901 && (
            <>
              <Button onClick={() => setshowDrawer(!showDrawer)}>
                <ListIcon style={{ color: "white" }} />
              </Button>
            </>
          )}
        </div>
      )}
      <div
        className={css?.headerDrawer}
        style={{ display: showDrawer ? "flex" : "none" }}
      >
        <div
          className={css?.drawerOption}
          onClick={() => {
            history({
              pathname: `/`,
            });
          }}
        >
          Schedule pickup
        </div>
        <div
          className={css?.drawerOption}
          onClick={() => {
            history("/BecomeAMoover");
          }}
        >
          Become a moover
        </div>
        <div
          className={css?.drawerOption}
          onClick={() => {
            history("/HowItWorks");
          }}
        >
          How it works
        </div>
      </div>
    </div>
  );
}

export default MarketingHeader;
