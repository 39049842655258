import React, { useState } from "react";
// importing the css
import css from "../../style/booking.module.css";
import { CustomTooltip } from "../Tooltip/tooltip";
import CouponCode from "./CouponCode";

const Delivery = ({ handleReturnVal, bookingTotalModel, distanceInKm }) => {
  return (
    <div className={css?.priceDivConfirmation}>
      <div className={css?.priceDivTopConfirmation}>
        <div className={css?.priceBreakdownLineConfirmation}>
          <span className={css?.priceBreakdownLeftWord}>Base Fare</span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.baseFare, "$")}
          </span>
        </div>
        <div className={css?.priceBreakdownLineConfirmation}>
          <span className={css?.priceBreakdownLeftWord}>
            Distance {`(${distanceInKm}km)`}
          </span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.totalDistance, "$")}
          </span>
        </div>
        <div className={css?.priceBreakdownLineConfirmation}>
          <span className={css?.priceBreakdownLeftWord}>Labour</span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.labourSurcharge, "$")}
          </span>
        </div>
        <div className={css?.priceBreakdownLineConfirmation}>
          <span className={css?.priceBreakdownLeftWord}>Extra moover</span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(
              bookingTotalModel?.delivery_ExtraMooverCharge,
              "$"
            )}
          </span>
        </div>
        <div
          className={css?.priceBreakdownLineConfirmation}
          style={{ fontWeight: "700" }}
        >
          <span className={css?.priceBreakdownLeftWord}>Subtotal</span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.totalChargeBTax, "$")}
          </span>
        </div>
      </div>

      <div
        className={css?.priceDivMid}
        style={{ borderBottom: "1px solid var(--secondary)" }}
      >
        {/* <div className={css?.priceBreakdownLineConfirmation}>
          <span className={css?.priceBreakdownLeftWord}>Booking fee</span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.serviceFee, "$")}
          </span>
        </div> */}
        <div className={css?.priceBreakdownLineConfirmation}>
          <span className={css?.priceBreakdownLeftWord}>Service Fee</span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.totalServiceFee, "$")}
          </span>
        </div>
        <div className={css?.priceBreakdownLineConfirmation}>
          <span className={css?.priceBreakdownLeftWord}>
            GST ( {bookingTotalModel?.totalTaxGstpstrate}% )
          </span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.gstandPst, "$")}
          </span>
        </div>
        <div className={css?.priceBreakdownLineConfirmation}>
          <span className={css?.priceBreakdownLeftWord}>Discount</span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.discount, "")}
          </span>
        </div>
      </div>
      <div className={css?.priceDivBottom}>
        <div
          className={css?.priceBreakdownLineConfirmation}
          style={{ fontStyle: "bold", fontSize: "20px", color: "white" }}
        >
          <span className={css?.priceBreakdownLeftWord}>Total</span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.totalCharge, "$")}
          </span>
        </div>
      </div>
    </div>
  );
};
const Mooving = ({ handleReturnVal, bookingTotalModel }) => {
  return (
    <div className={css?.priceDivConfirmation}>
      <div className={css?.priceDivTopConfirmation}>
        <div className={css?.priceBreakdownLineConfirmation}>
          <span className={css?.priceBreakdownLeftWord}>Hourly rate</span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.hourlyRate, "$")}
          </span>
        </div>

        <div className={css?.priceBreakdownLineConfirmation}>
          <span className={css?.priceBreakdownLeftWord}>
            Est. work time (hrs)
          </span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.workTime, "")}
          </span>
        </div>
        <div className={css?.priceBreakdownLineConfirmation}>
          <span className={css?.priceBreakdownLeftWord}>Travel time (hrs)</span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.travelTime, "")}
          </span>
        </div>
        <div
          className={css?.priceBreakdownLineConfirmation}
          style={{ fontWeight: "700" }}
        >
          <span className={css?.priceBreakdownLeftWord}>Est. subtotal</span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.totalChargeBTax, "$")}
          </span>
        </div>
      </div>

      <div
        className={css?.priceDivMid}
        style={{ borderBottom: "1px solid var(--secondary)" }}
      >
        <div className={css?.priceBreakdownLineConfirmation}>
          <span className={css?.priceBreakdownLeftWord}>Service Fee</span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.totalServiceFee, "$")}
          </span>
        </div>
        <div className={css?.priceBreakdownLineConfirmation}>
          <span className={css?.priceBreakdownLeftWord}>
            GST ( {bookingTotalModel?.totalTaxGstpstrate}% )
          </span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.gstandPst, "$")}
          </span>
        </div>
        <div className={css?.priceBreakdownLineConfirmation}>
          <span className={css?.priceBreakdownLeftWord}>Discount</span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.discount, "$")}
          </span>
        </div>
      </div>
      <div className={css?.priceDivBottom}>
        <div
          className={css?.priceBreakdownLineConfirmation}
          style={{ fontStyle: "bold", fontSize: "20px", color: "white" }}
        >
          <span className={css?.priceBreakdownLeftWord}>Est. total:</span>
          <span className={css?.priceBreakdownRightWord}>
            {handleReturnVal(bookingTotalModel?.totalCharge, "$")}
          </span>
        </div>
      </div>
      <div className={css?.estimationNote2}>
        This estimate is based on the information provided. The final total will
        be calculated based on the total work-time needed to complete the job.
      </div>
    </div>
  );
};

const TotalConfirm = ({
  type,
  bookingTotalModel,
  distanceInKm,
  coupon,
  handleUpdateBookingObject,
}) => {
  const handleReturnVal = (val, symbol) => {
    return val ? symbol + val : "-";
  };
  return (
    <div className={css?.totalConfirmMainDIv}>
      <div className={css?.totalConfirmMainDIvL1}>
        <div className={css?.totalHeading}>
          Total
          <CustomTooltip
            title={
              ["Delivery", "Business"].includes(type) ? (
                <Delivery
                  handleReturnVal={handleReturnVal}
                  distanceInKm={distanceInKm}
                  bookingTotalModel={bookingTotalModel}
                />
              ) : (
                <Mooving
                  handleReturnVal={handleReturnVal}
                  bookingTotalModel={bookingTotalModel}
                />
              )
            }
          >
            <img
              src="images/info2.svg"
              style={{
                display: "inline",
                marginLeft: "7px",
                width: "25px",
                cursor: "pointer",
              }}
              alt=""
            />
          </CustomTooltip>
        </div>
        <div className={css?.totalText}>
          {bookingTotalModel?.totalCharge + " CAD"}
        </div>
      </div>
      <CouponCode
        coupon={coupon}
        handleUpdateBookingObject={handleUpdateBookingObject}
      />
    </div>
  );
};

export default TotalConfirm;
