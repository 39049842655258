import React from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/rejectSummary.module.css";
import { useNavigate, useParams } from "react-router-dom";

const RejectSummary = () => {
  const history = useNavigate();
  const { userEmail } = useParams();
  const handleViewCards = () => {
    history("/userProfile/" + userEmail);
  };
  const handleGoToHome = () => {
    window.location.href = "https://moovez.ca/";
  };
  return (
    <div className={css?.mainDiv}>
      <div className={css?.cardMainDiv}>
        <div className={css?.headingMainDiv}>Opps!</div>
        <div className={css?.subHeading}>You selected reject</div>
        <div className={css?.generalText}>
          We couldn't complete your move. Please speak to out movers if there is
          an issue with the order summary or make sure that your payment method
          is correct.
        </div>
        <div className={css?.buttonGroup}>
          <div className={css?.Btn1Style} onClick={() => handleViewCards()}>
            Show payment method
          </div>
          <div className={css?.Btn2Style} onClick={() => handleGoToHome()}>
            Return to homepage
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectSummary;
