import * as Sentry from "@sentry/react";

export const updateUserCardStatus = (hasCard) => {
  try {
    let user = localStorage.getItem("wemoovezUserProfile");
    if (!user) return;
    user = JSON.parse(user);
    user = { ...user, hasCard };
    localStorage.setItem("wemoovezUserProfile", JSON.stringify(user));
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};

export const generateBookingLogs = (name, value, temp, triggeredBy, type) => {
  const log = type + "Log";
  let logFile = localStorage.getItem(log);
  const newEntry = { name, value, temp, triggeredBy };
  if (logFile) {
    logFile = JSON.parse(logFile);
    if (Array.isArray(logFile) && logFile.length < 200)
      localStorage.setItem(log, JSON.stringify([...logFile, newEntry]));
    else localStorage.setItem(log, JSON.stringify([newEntry]));
  } else {
    localStorage.setItem(log, JSON.stringify([newEntry]));
  }
};

export const handleValidatePhoneNumber = (e, setphoneNo) => {
  try {
    let number = e?.target?.value;
    let numArr = [...number];
    if (numArr?.length < 13) {
      let newNum = "";
      for (let i = 0; i < numArr?.length; i++) {
        if (numArr[i] >= "0" && numArr[i] <= "9") {
          newNum = `${newNum}${i == 3 || i == 7 ? "-" : ""}${numArr[i]}`;
        } else if (numArr[i] === "-" && (i === 3 || i === 7)) {
          newNum = newNum + numArr[i];
        } else {
          setphoneNo(newNum);
          return null;
        }
      }
      setphoneNo(newNum);
    }
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};

export const clearBookingsFromLocalStorage = () => {
  localStorage.removeItem("wemoovezMoversObject");
  localStorage.removeItem("wemoovezDeliveryObject");
  localStorage.removeItem("wemoovezMoovingObject");
  localStorage.removeItem("wemoovezMovingObject");
  localStorage.removeItem("wemoovezBusinessObject");
  localStorage.removeItem("wemoovezMoversObjectLog");
  localStorage.removeItem("wemoovezDeliveryObjectLog");
  localStorage.removeItem("wemoovezMoovingObjectLog");
  localStorage.removeItem("wemoovezMovingObjectLog");
  localStorage.removeItem("wemoovezBusinessObjectLog");
  localStorage.removeItem("currentBookingType");
};
