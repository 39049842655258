import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
// importing the css
import css from "../../style/booking.module.css";
// importing the components
// First Screen
import AppCards from "../../components/Booking/AppCards";
import BookingTypeDropdown from "../../components/Booking/BookingTypeDropdown";
import CalanderModal from "../../components/Booking/CalanderModal";
import Counter from "../../components/Booking/Counter";
import LoadSizeButtons from "../../components/Booking/LoadSize";
import Map from "../../components/Booking/Map";
import MapInput from "../../components/Booking/MapInput";
import MoveSize from "../../components/Booking/MoveSizeDropdown";
import MoveTypeDropdown from "../../components/Booking/MoveTypesDropdown";
import RoundButton from "../../components/Booking/RoundButton";
import Slider from "../../components/Booking/Slider2";
import TotalHours from "../../components/Booking/TotalHours";
import Upload from "../../components/Booking/Upload";
import ModalComponent from "../../components/Modals/Modal";
import CTASection from "../../components/Booking/CTASection";
//Second Screen
import ConfirmDetails from "../../components/Booking/ConfirmDetails";
import RecipentInfo from "../../components/Booking/RecipentInfo";
import TotalConfirm from "../../components/Booking/TotalConfirm";
// Payment Screen
import PaymentScreen from "../../components/Booking/PaymentScreen";
// importing liberaries
import { useDispatch, useSelector } from "react-redux";
import {
  clearBookingsFromLocalStorage,
  generateBookingLogs,
} from "../../Utilities/general";
//importing APIs
import { useNavigate } from "react-router";
import {
  getBookingTotal,
  getMovePlaces,
  getMoveSize,
  postBooking,
  updateBooking,
} from "../../store/actions/bookingActions";
import { getAllDeliverySlots } from "../../store/actions/deliverySlotsActions";
import { getAllVehicles } from "../../store/actions/vehicleTypeActions";
import LoadingLottie from "../../components/Lottie/LoadingLottie";
import { userPaymentMethods } from "../../store/actions/paymentActions";

// function to get the scren width
function useWindowSize() {
  const [size, setSize] = useState([]);
  useEffect(() => {
    try {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.addEventListener("resize", handleResize);
      };
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);
  return size;
}

const Booking = (props) => {
  const history = useNavigate();
  const [total, setTotal] = useState();
  const [errorObject, setErrorObject] = useState({});
  // const [hideBookingTypeDD, setHideBookingTypeDD] = useState(false);
  // Booking items
  const [id, setId] = useState(0);
  const [pickupLocation, setPickupLocation] = useState("");
  const [pickupLatitude, setPickupLatitude] = useState("");
  const [pickupLongitude, setPickupLongitude] = useState("");
  const [dropoffLocation, setDropoffLocation] = useState("");
  const [dropoffLatitude, setDropoffLatitude] = useState("");
  const [dropoffLongitude, setDropoffLongitude] = useState("");
  const [vehicleTypeId, setVehicleTypeId] = useState(1002);
  const [vehicle, setVehicle] = useState({});
  const [deliveryDate, setDeliveryDate] = useState("");
  const [type, setType] = useState("Moving");
  const [deliverySlotId, setDeliverySlotId] = useState(1000);
  const [exactTime, setExactTime] = useState("");
  const [labourNeeded, setLabourNeeded] = useState(1);
  const [totalOverweightItems, setTotalOverweightItems] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState(0);
  const [pickupTotalFloors, setPickupTotalFloors] = useState(0);
  const [dropoffTotalFloors, setDropoffTotalFloors] = useState(0);
  const [pickupMedium, setPickupMedium] = useState("elevator");
  const [dropoffMedium, setDropoffMedium] = useState("elevator");
  const [distanceInKm, setDistanceInKm] = useState(0);
  const [durationInMins, setDurationInMins] = useState(0);
  const [bookingSizeId, setBookingSizeId] = useState(1);
  const [dropOffPersonName, setDropOffPersonName] = useState("");
  const [dropOffPersonPhone, setDropOffPersonPhone] = useState("");
  const [pickUpPersonName, setPickUpPersonName] = useState("");
  const [pickUpPersonPhone, setPickUpPersonPhone] = useState("");
  const [dropOffInstructions, setDropOffInstructions] = useState("");
  const [pickUpInstructions, setPickUpInstructions] = useState("");
  const [tripStatus, setTripStatus] = useState("");
  const [status, setStatus] = useState("");
  const [userId, setUserId] = useState(0);
  const [numberOfHours, setNumberOfHours] = useState(1);
  const [stops, setStops] = useState([]);
  const [isDeliverNow, setIsDeliverNow] = useState(false);
  const [isHalfLoad, setIsHalfLoad] = useState(false);
  const [moveTypeId, setMoveTypeId] = useState(0);
  const [moveSizeId, setMoveSizeId] = useState(0);
  const [bookingFiles, setBookingFiles] = useState([]);
  const [bookingTotalModel, setBookingTotalModel] = useState({});
  const [coupon, setCoupon] = useState("");

  //
  //
  // DEFINING
  // VARIABLES
  //
  //

  // dispatch redux actions
  const dispatch = useDispatch();

  const [vehicleId, setVehicleId] = useState(0);
  // move places
  const [movePlaces, setmovePlaces] = useState();
  // move size
  const [moveSize, setmoveSize] = useState();
  // getting the dimentions
  const [height, width] = useWindowSize();

  const [openCalander, setOpenCalander] = useState(false);

  const [showGenericModal, setShowGenericModal] = useState(false);
  const [genericModalMessage, setGenericModalMessage] = useState("");
  const [showLottie, setShowLottie] = useState(true);
  const [showPaymentMessage, setShowPaymentMessage] = useState(false);

  //
  //
  // GETTING STATES FROM REDUX
  //
  //
  // getting vehicle info
  const { vehicleTypesInfo } = useSelector(
    (state) => state.vehicleTypesReducer
  );
  // Getting delivery slots
  const { deliverySlotsInfo } = useSelector(
    (state) => state.deliverySlotsReducer
  );

  // getting user details from reducer
  const { loginInfo } = useSelector((state) => state.loginReducer);

  //Modal consts
  const [open, setOpen] = React.useState(false);
  const [confirm1Moover, setConfirm1Moover] = useState(false);
  const handleClose = () => setOpen(false);
  const [Dateopen, setDateOpen] = React.useState(false);
  const handleDateClose = () => setDateOpen(false);

  //
  //
  // FUNCTIONS
  //
  //
  //

  const bookingObjToStates = (bookingObj) => {
    setId(bookingObj?.id ?? 0);
    setPickupLocation(bookingObj?.pickupLocation ?? "");
    setPickupLatitude(bookingObj?.pickupLatitude ?? "");
    setPickupLongitude(bookingObj?.pickupLongitude ?? "");
    setDropoffLocation(bookingObj?.dropoffLocation ?? "");
    setDropoffLatitude(bookingObj?.dropoffLatitude ?? "");
    setDropoffLongitude(bookingObj?.dropoffLongitude ?? "");
    setVehicleTypeId(bookingObj?.vehicleTypeId);
    setVehicle(bookingObj?.vehicle ?? {});
    setDeliveryDate(bookingObj?.deliveryDate ?? "");
    setType(bookingObj?.type ?? "Moving");
    setDeliverySlotId(bookingObj?.deliverySlotId ?? 1000);
    setExactTime(bookingObj?.exactTime ?? "");
    setLabourNeeded(bookingObj?.labourNeeded ?? 1);
    setTotalOverweightItems(bookingObj?.totalOverweightItems ?? 0);
    setEstimatedTime(bookingObj?.estimatedTime ?? 0);
    setPickupTotalFloors(bookingObj?.pickupTotalFloors ?? 0);
    setDropoffTotalFloors(bookingObj?.dropoffTotalFloors ?? 0);
    setPickupMedium(bookingObj?.pickupMedium ?? "elevator");
    setDropoffMedium(bookingObj?.dropoffMedium ?? "elevator");
    setDistanceInKm(bookingObj?.distanceInKm ?? 0);
    setDurationInMins(bookingObj?.durationInMins ?? 0);
    setBookingSizeId(bookingObj?.bookingSizeId ?? 1);
    setDropOffPersonName(bookingObj?.dropOffPersonName ?? "");
    setDropOffPersonPhone(bookingObj?.dropOffPersonPhone ?? "");
    setPickUpPersonName(bookingObj?.pickUpPersonName ?? "");
    setPickUpPersonPhone(bookingObj?.pickUpPersonPhone ?? "");
    setDropOffInstructions(bookingObj?.dropOffInstructions ?? "");
    setPickUpInstructions(bookingObj?.pickUpInstructions ?? "");
    setTripStatus(bookingObj?.tripStatus ?? "");
    setStatus(bookingObj?.status ?? "");
    setUserId(bookingObj?.userId ?? 0);
    setNumberOfHours(bookingObj?.numberOfHours ?? 1);
    setStops(bookingObj?.stops ?? []);
    setIsDeliverNow(bookingObj?.isDeliverNow ?? true);
    setIsHalfLoad(bookingObj?.isHalfLoad ?? false);
    setMoveTypeId(bookingObj?.moveTypeId ?? 0);
    setMoveSizeId(bookingObj?.moveSizeId ?? 0);
    setCoupon(bookingObj?.coupon ?? "");
    setBookingFiles(bookingObj?.bookingFiles ?? []);
    setBookingTotalModel(bookingObj?.bookingTotalModel ?? {});
  };

  const statesToBookingObj = () => {
    return {
      id,
      pickupLocation,
      pickupLatitude,
      pickupLongitude,
      dropoffLocation,
      dropoffLatitude,
      dropoffLongitude,
      vehicleTypeId,
      vehicle,
      deliveryDate,
      type,
      deliverySlotId,
      exactTime,
      labourNeeded,
      totalOverweightItems,
      estimatedTime,
      pickupTotalFloors,
      dropoffTotalFloors,
      pickupMedium,
      dropoffMedium,
      distanceInKm,
      durationInMins,
      bookingSizeId,
      dropOffPersonName,
      dropOffPersonPhone,
      pickUpPersonName,
      pickUpPersonPhone,
      dropOffInstructions,
      pickUpInstructions,
      tripStatus,
      status,
      userId: loginInfo?.id ?? userId,
      numberOfHours,
      stops,
      isDeliverNow,
      isHalfLoad,
      moveTypeId,
      moveSizeId,
      coupon,
      bookingFiles,
      bookingTotalModel,
    };
  };

  const saveBookingInLS = (
    name,
    value,
    triggeredBy,
    bookingType,
    updatedBooking
  ) => {
    localStorage.setItem(
      `wemoovez${bookingType ?? "Moving"}Object`,
      JSON.stringify(updatedBooking)
    );
    generateBookingLogs(
      name,
      value,
      updatedBooking,
      triggeredBy,
      `wemoovez${bookingType}Object`
    );
  };

  const getBookingFromLS = (bookingType) => {
    const bookingFromLS = localStorage.getItem(
      `wemoovez${bookingType ?? "Moving"}Object`
    );
    if (bookingFromLS) {
      const parsedBooking = JSON.parse(bookingFromLS);
      bookingObjToStates(parsedBooking);
    }
  };

  const updateBookingStateBasedOnName = (name, value) => {
    if (!name) return;
    switch (name) {
      case "id":
        setId(value);
        break;
      case "pickupLocation":
        setPickupLocation(value);
        break;
      case "pickupLatitude":
        setPickupLatitude(value);
        break;
      case "pickupLongitude":
        setPickupLongitude(value);
        break;
      case "dropoffLocation":
        setDropoffLocation(value);
        break;
      case "dropoffLatitude":
        setDropoffLatitude(value);
        break;
      case "dropoffLongitude":
        setDropoffLongitude(value);
        break;
      case "vehicleTypeId":
        setVehicleTypeId(value);
        break;
      case "vehicle":
        setVehicle(value);
        break;
      case "deliveryDate":
        setDeliveryDate(value);
        break;
      case "deliverySlotId":
        setDeliverySlotId(value);
        break;
      case "type":
        setType(value);
        break;
      case "exactTime":
        setExactTime(value);
        break;
      case "labourNeeded":
        setLabourNeeded(value);
        break;
      case "totalOverweightItems":
        setTotalOverweightItems(value);
        break;
      case "estimatedTime":
        setEstimatedTime(value);
        break;
      case "pickupTotalFloors":
        setPickupTotalFloors(value);
        break;
      case "dropoffTotalFloors":
        setDropoffTotalFloors(value);
        break;
      case "pickupMedium":
        setPickupMedium(value);
        break;
      case "dropoffMedium":
        setDropoffMedium(value);
        break;
      case "distanceInKm":
        setDistanceInKm(value);
        break;
      case "durationInMins":
        setDurationInMins(value);
        break;
      case "bookingSizeId":
        setBookingSizeId(value);
        break;
      case "dropOffPersonName":
        setDropOffPersonName(value);
        break;
      case "dropOffPersonPhone":
        setDropOffPersonPhone(value);
        break;
      case "pickUpPersonName":
        setPickUpPersonName(value);
        break;
      case "pickUpPersonPhone":
        setPickUpPersonPhone(value);
        break;
      case "dropOffInstructions":
        setDropOffInstructions(value);
        break;
      case "pickUpInstructions":
        setPickUpInstructions(value);
        break;
      case "tripStatus":
        setTripStatus(value);
        break;
      case "status":
        setStatus(value);
        break;
      case "userId":
        setUserId(value);
        break;
      case "numberOfHours":
        setNumberOfHours(value);
        break;
      case "stops":
        setStops(JSON.parse(JSON.stringify(value)));
        break;
      case "isDeliverNow":
        setIsDeliverNow(value);
        break;
      case "isHalfLoad":
        setIsHalfLoad(value);
        break;
      case "moveSizeId":
        setMoveSizeId(value);
        break;
      case "coupon":
        setCoupon(value);
        break;
      case "moveTypeId":
        setMoveTypeId(value);
        break;
      case "bookingFiles":
        setBookingFiles(value);
        break;
      case "bookingTotalModel":
        setBookingTotalModel(value);
        break;
      default:
        break;
    }
  };

  const handleUpdateBookingObject = (name, value, triggeredBy) => {
    try {
      let bookingTypeFromLS = localStorage.getItem("currentBookingType");
      let bookingTypeFromState = type;
      if (!bookingTypeFromLS) {
        bookingTypeFromLS = type;
        localStorage.setItem("currentBookingType", type);
      } else if (bookingTypeFromLS !== type && name !== "type") {
        localStorage.removeItem(`wemoovez${type}Object`);
        bookingTypeFromState = bookingTypeFromLS;
        setType(bookingTypeFromLS);
      }
      let updatedBooking;
      if (name) {
        if (name === "type") {
          localStorage.setItem("currentBookingType", value);
          bookingTypeFromState = value;
          if (value === "Movers") {
            setDropoffLatitude("");
            setDropoffLongitude("");
            setDropoffLocation("");
            setDropOffPersonName("");
            setDropOffPersonPhone("");
            setDropOffInstructions("");
          }
        }
        updateBookingStateBasedOnName(name, value);
        updatedBooking = { ...statesToBookingObj(), [name]: value };
      } else {
        bookingObjToStates(value);
        updatedBooking = value;
      }
      saveBookingInLS(
        name,
        value,
        triggeredBy,
        bookingTypeFromState,
        updatedBooking
      );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleCloseCalander = () => {
    try {
      setOpenCalander(false);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleAddDefaultDate = () => {
    try {
      setDeliveryDate(new Date()?.toString()?.split(" GMT")[0]);
      setIsDeliverNow(true);
      setExactTime("");
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleToggleGenericError = (message) => {
    if (message === "confirm1Moover") return;
    setShowGenericModal(message ? true : false);
    setGenericModalMessage(message ? message : "");
  };

  const handleValidatePostBooking = () => {
    if (tripStatus === "Confirmation") return null;
    if (!pickupLocation)
      return "Pickup location missing! please fill all the fields...";

    if (!dropoffLocation && type !== "Movers")
      return "Drop off location missing! please fill all the fields...";

    if (!vehicleTypeId && type !== "Movers")
      return "Vehicle type missing! please fill all the fields...";
    if (!moveTypeId && type === "Moving")
      return "Move type missing! please fill all the fields...";
    if (!moveSizeId && type === "Moving")
      return "Move size missing! please fill all the fields...";

    if (!distanceInKm && type !== "Movers") {
      return "Sorry, we cannot deliver on the following route. Please try again later.";
    }
    if (labourNeeded < 2 && !confirm1Moover && !id) {
      setOpen(true);
      return "confirm1Moover";
    }
  };

  const handleValidateConfirmBooking = () => {
    let errorCount = 0;
    let tempError = {};
    if (dropoffLocation && !dropOffPersonName) {
      tempError.dropOffPersonName = true;
      errorCount += 1;
    }
    if (dropoffLocation && !dropOffPersonPhone) {
      tempError.dropOffPersonPhone = true;
      errorCount += 1;
    }

    if (!pickUpPersonName) {
      tempError.pickUpPersonName = true;
      errorCount += 1;
    }

    if (!pickUpPersonPhone) {
      tempError.pickUpPersonPhone = true;
      errorCount += 1;
    }
    if (errorCount > 0) {
      setErrorObject(tempError);
      return "Please fill all the fields!";
    }
    if (pickUpPersonPhone.length !== 12) {
      tempError.pickUpPersonPhone = true;
      errorCount += 1;
    }
    if (dropoffLocation && dropOffPersonPhone.length !== 12) {
      tempError.dropOffPersonPhone = true;
      errorCount += 1;
    }
    if (errorCount > 0) {
      setErrorObject(tempError);
      return "Invalid phone number!";
    }
  };

  const handleBookNow = (saveDefaultDate) => {
    try {
      let error = handleValidatePostBooking();
      if (error) return handleToggleGenericError(error);
      if (!loginInfo?.id) {
        localStorage.setItem("redirectToBooking", "true");
        localStorage.setItem(
          "loginBooking",
          JSON.stringify(statesToBookingObj())
        );
        localStorage.setItem("loginBookingType", type);
        history("/login");
        return;
      }
      if (type === "Delivery") setIsHalfLoad(false);
      if (saveDefaultDate) handleAddDefaultDate();
      else setIsDeliverNow(false);
      setShowLottie(true);
      if (id)
        updateBooking(
          id,
          { ...statesToBookingObj(), tripStatus: "Confirmation" },
          handleUpdateBookingObject,
          setShowLottie
        );
      else
        postBooking(
          {
            ...statesToBookingObj(),
            userId: loginInfo.id,
            tripStatus: "Confirmation",
          },
          handleUpdateBookingObject,
          setShowLottie
        );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      setShowLottie(false);
    }
  };

  const handleProceedScheduleForLater = () => {
    try {
      if (!exactTime) {
        setShowGenericModal(true);
        setGenericModalMessage("Please select delivery slot.");
      }
      let newDeliveryDate;
      if (deliveryDate) {
        const selectedDate = new Date();
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        if (selectedDate < todayDate) {
          setDateOpen(true);
          return;
        }
        newDeliveryDate = deliveryDate;
      } else newDeliveryDate = new Date().toString()?.split(" GMT")[0];
      handleUpdateBookingObject("deliveryDate", newDeliveryDate, "10.2");
      setOpenCalander(false);
      let error = handleValidatePostBooking();
      if (error) return handleToggleGenericError(error);
      if (!loginInfo?.id) {
        localStorage.setItem("redirectToBooking", "true");
        history("/login");
        return;
      }
      setShowLottie(true);
      if (id)
        updateBooking(
          id,
          {
            ...statesToBookingObj(),
            isDeliverNow: false,
            tripStatus: "Confirmation",
          },
          handleUpdateBookingObject,
          setShowLottie
        );
      else
        postBooking(
          {
            ...statesToBookingObj(),
            isDeliverNow: false,
            tripStatus: "Confirmation",
          },
          handleUpdateBookingObject,
          setShowLottie
        );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      setShowLottie(false);
    }
  };
  const handleScheduleForLater = () => {
    try {
      setOpenCalander(true);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  // checking the confirmation credentials
  const handleConfirmOrder = () => {
    try {
      let error = handleValidateConfirmBooking();
      if (error) return handleToggleGenericError(error);
      setShowLottie(true);
      dispatch(
        updateBooking(
          id,
          {
            ...statesToBookingObj(),
            tripStatus: "Payment",
          },
          handleUpdateBookingObject,
          setShowLottie
        )
      );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      setShowLottie(false);
    }
  };

  // edit booking
  const handleEditOrder = () => {
    try {
      setShowLottie(true);
      updateBooking(
        id,
        {
          ...statesToBookingObj(),
          tripStatus: "Inprogress",
        },
        handleUpdateBookingObject,
        setShowLottie
      ).then(() => handleSelectVehicleInSlider());
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      setShowLottie(false);
    }
  };

  //async function for confirming 1 moover
  const confirm1mooverfunc = () => {
    setConfirm1Moover(true);
    setOpen(false);
  };

  const handleReturnTotalParams = () => {
    let totalReqObj = {};
    const commonFields = ["type", "labourNeeded"];
    const tempBookingObj = statesToBookingObj();
    commonFields.forEach((key) => {
      if (!tempBookingObj[key]) return false;
      totalReqObj[key] = tempBookingObj[key];
    });
    totalReqObj = {
      ...totalReqObj,
      isHalfLoad: false,
      pickupMedium: "elevator",
      dropoffMedium: "elevator",
      id: 0,
      userId: 0,
      couponId: 0,
      coupon,
      discount: 0,
      moveSizeId: 0,
      vehicleTypeId,
      numberOfHours: 0,
      stops: stops?.length ?? 0,
    };
    if (!durationInMins && type !== "Movers") return false;
    if (!distanceInKm && type !== "Movers") return false;
    totalReqObj.totalDuration = durationInMins;
    totalReqObj.distanceInKm = distanceInKm;

    if (type === "Moving") {
      if (!vehicleTypeId || !moveSizeId) return false;
      return {
        ...totalReqObj,
        moveSizeId,
      };
    }
    if (type === "Movers") {
      if (!numberOfHours) return false;
      return {
        ...totalReqObj,
        numberOfHours,
        vehicleTypeId: 0,
      };
    }
    if (type === "Delivery") {
      if (!vehicleTypeId) return false;
      return {
        ...totalReqObj,
      };
    }
    if (type === "Business") {
      if (!vehicleTypeId || !isHalfLoad) return false;
      return {
        ...totalReqObj,
        isHalfLoad,
      };
    }
    return false;
  };

  const get1MoverModalTxt = () => {
    if (type === "Moving")
      return "Are you sure 1 mover is enough? If this move involves big items, please consider 2 movers OR we'll need your help to load and unload the items.";
    else if (type === "Movers")
      return "Are you sure 1 mover is enough? If you need big items moved, please consider 2 movers OR require need your help.";
    return "Are you sure 1 mover is enough? If it's a big item make sure to select 2 movers OR we'll need your help to load and unload.";
  };

  const handleChangeMoveType = (val) => {
    try {
      const mTId = val ? parseInt(val) : 0;
      if (!mTId) return;
      handleUpdateBookingObject("moveTypeId", mTId, "1.1");
      handleUpdateBookingObject("moveSizeId", null, "1.2");
      dispatch(getMoveSize(mTId, setmoveSize));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const updateUserCardStatus = () => {
    try {
      let user = localStorage.getItem("wemoovezUserProfile");
      if (user) {
        user = JSON.parse(user);
        userPaymentMethods(user.id);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handleSelectVehicleInSlider = () => {
    const index = vehicleTypesInfo?.findIndex(
      (vehicle) => vehicle?.id === vehicleTypeId
    );
    const ch_id = index >= 0 ? index : 0;
    let theDot = document.getElementById(`vehicleSliderDot${ch_id}`)?.click();
  };

  //
  //
  // USE
  // EFFECT
  // ACTIONS
  //
  //

  // populate delivery Object from reducer

  useEffect(() => {
    function handleAlertUser(event) {
      event.preventDefault();
      event.returnValue = "";
      return "";
    }
    window.addEventListener("beforeunload", handleAlertUser, {
      capture: true,
    });
    return () => {
      window.removeEventListener("beforeunload", handleAlertUser);
    };
  }, []);

  useEffect(() => {
    try {
      updateUserCardStatus();
      let bookingTypeFromLS =
        props?.type ?? localStorage.getItem("currentBookingType");
      // const hideBookingDropdown = localStorage.getItem("hideBookingDropdown");
      // setHideBookingTypeDD(hideBookingDropdown == "1");
      if (!bookingTypeFromLS)
        localStorage.setItem("currentBookingType", "Moving");
      else {
        let temp = localStorage.getItem(`wemoovez${bookingTypeFromLS}Object`);
        if (temp) {
          temp = JSON.parse(temp);
          if (temp?.type && temp?.type === bookingTypeFromLS) {
            window.setTimeout(() => bookingObjToStates(temp), 100);
            if (temp?.moveTypeId)
              dispatch(getMoveSize(temp?.moveTypeId, setmoveSize));
          } else {
            localStorage.removeItem(`wemoovez${bookingTypeFromLS}Object`);
            localStorage.setItem("currentBookingType", "Moving");
          }
        } else {
          const bookingWIthUpdatedType = {
            ...statesToBookingObj(),
            type: bookingTypeFromLS,
          };
          generateBookingLogs(
            null,
            bookingWIthUpdatedType,
            "22",
            `wemoovez${bookingTypeFromLS}Object`
          );
          localStorage.setItem(
            `wemoovez${bookingTypeFromLS}Object`,
            JSON.stringify(bookingWIthUpdatedType)
          );
          window.setTimeout(
            () => bookingObjToStates(bookingWIthUpdatedType),
            100
          );
        }
      }
      // call all the APIs
      dispatch(getAllVehicles());
      dispatch(getAllDeliverySlots());
      dispatch(getMovePlaces(setmovePlaces));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
    const hideLottie = setTimeout(() => {
      setShowLottie(false);
    }, 3000);
    return () => {
      clearTimeout(hideLottie);
      clearBookingsFromLocalStorage();
    };
  }, []);

  useEffect(() => {
    try {
      // CALCULATING TOTAL WHENEVER THERE IS A CHANGE
      let calculateTotal;
      const totalReqParams = handleReturnTotalParams();
      if (totalReqParams) {
        calculateTotal = setTimeout(() => {
          getBookingTotal(totalReqParams, setTotal);
        }, 2000);
      } else {
        setTotal(null);
      }
      return () => clearTimeout(calculateTotal);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [
    type,
    distanceInKm,
    labourNeeded,
    isHalfLoad,
    pickupMedium,
    dropoffMedium,
    id,
    userId,
    moveSizeId,
    coupon,
    numberOfHours,
    vehicleTypeId,
    isHalfLoad,
    durationInMins,
  ]);

  // useEffect to update vehicle counter
  useEffect(() => {
    try {
      if (vehicleTypesInfo?.length) {
        if (vehicleTypeId) {
          handleSelectVehicleInSlider();
        } else {
          handleUpdateBookingObject(
            "vehicleTypeId",
            vehicleTypesInfo[0].id,
            "2"
          );
          let theDot = document.getElementById(`vehicleSliderDot0`).click();
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [vehicleTypesInfo, vehicleId]);

  // useEffect post booking if user confirms 1 mover
  useEffect(() => {
    try {
      if (confirm1Moover) handleBookNow();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [confirm1Moover]);

  return (
    <div>
      <CalanderModal
        deliveryDate={deliveryDate}
        type={type}
        exactTime={exactTime}
        handleUpdateBookingObject={handleUpdateBookingObject}
        openState={openCalander}
        closeFunction={handleCloseCalander}
        btnClickFunc1={handleProceedScheduleForLater}
        timeSlots={deliverySlotsInfo}
      />
      <ModalComponent
        text={get1MoverModalTxt()}
        openState={open}
        closeFunction={handleClose}
        btnText1={"Yes, I am sure"}
        btnClickFunc1={() => confirm1mooverfunc()}
        btnText2={"Edit Booking"}
        btnClickFunc2={() => setOpen(false)}
      />
      <ModalComponent
        text={"Please Enter Current or any Future Date.."}
        openState={Dateopen}
        closeFunction={handleDateClose}
        btnText1={"Ok"}
        btnClickFunc1={() => {
          setDateOpen(false);
        }}
      />
      <ModalComponent
        text={genericModalMessage}
        openState={showGenericModal}
        closeFunction={() => handleToggleGenericError()}
        btnText1={"Okay"}
        btnClickFunc1={() => handleToggleGenericError()}
      />
      {showLottie ? (
        <LoadingLottie showPaymentMessage={showPaymentMessage} />
      ) : (
        <></>
      )}
      {tripStatus === "" || tripStatus === "Inprogress" ? (
        <div className={css?.BookingMainDiv}>
          <div className={css?.bookingTopDiv}>
            <div className={css?.bookingLeftDiv} id="bookingLeftDiv">
              {/* <div className={css?.TopHeading}>
                {type === "Movers"
                  ? "Book movers (without the truck)"
                  : `Schedule your ${
                      type === "Delivery" ? "delivery" : "move"
                    }`}
              </div> */}
              {/* {hideBookingTypeDD ? (
                <></>
              ) : (
                <BookingTypeDropdown
                  otherTypes={["Moving", "Movers", "Delivery", "Business"]}
                  handleUpdateBookingObject={handleUpdateBookingObject}
                />
              )} */}
              {["Moving", "Delivery", "Business"].includes(type) &&
              Array.isArray(vehicleTypesInfo) &&
              vehicleTypesInfo?.length ? (
                <Slider
                  type={type}
                  vehicles={vehicleTypesInfo}
                  vehicleTypeId={vehicleTypeId}
                  handleUpdateBookingObject={handleUpdateBookingObject}
                />
              ) : (
                <></>
              )}
              <MapInput
                stops={stops}
                type={type}
                pickupLocation={pickupLocation}
                dropoffLocation={dropoffLocation}
                handleUpdateBookingObject={handleUpdateBookingObject}
              />
              {width < 601 && (
                <div className={css?.bookingRigthDiv}>
                  <Map
                    pickupLocation={pickupLocation}
                    dropoffLocation={dropoffLocation}
                    stops={stops}
                    pickupLatitude={pickupLatitude}
                    pickupLongitude={pickupLongitude}
                    dropoffLatitude={dropoffLatitude}
                    dropoffLongitude={dropoffLongitude}
                    distanceInKm={distanceInKm}
                    durationInMins={durationInMins}
                    handleUpdateBookingObject={handleUpdateBookingObject}
                  />
                </div>
              )}
              {type === "Business" && (
                <LoadSizeButtons
                  options={["1/2", "Full"]}
                  isHalfLoad={isHalfLoad}
                  handleUpdateBookingObject={handleUpdateBookingObject}
                />
              )}
              <Counter
                value={labourNeeded}
                max={4}
                min={1}
                type={type}
                increment={() =>
                  handleUpdateBookingObject(
                    "labourNeeded",
                    labourNeeded + 1,
                    "3"
                  )
                }
                decrement={() =>
                  handleUpdateBookingObject(
                    "labourNeeded",
                    labourNeeded - 1,
                    "4"
                  )
                }
              />

              {type === "Movers" && (
                <TotalHours
                  numberOfHours={numberOfHours}
                  type={type}
                  max={10}
                  min={1}
                  increment={() =>
                    handleUpdateBookingObject(
                      "numberOfHours",
                      numberOfHours + 1,
                      "5"
                    )
                  }
                  increment2={() =>
                    handleUpdateBookingObject(
                      "numberOfHours",
                      numberOfHours + 1,
                      "6"
                    )
                  }
                  decrement={() =>
                    handleUpdateBookingObject(
                      "numberOfHours",
                      numberOfHours - 1,
                      "7"
                    )
                  }
                />
              )}
              {["Moving"].includes(type) && movePlaces?.length ? (
                <MoveTypeDropdown
                  // Backend is sending move type in move place API
                  values={movePlaces}
                  moveTypeId={moveTypeId}
                  clickFunction={handleChangeMoveType}
                />
              ) : (
                <></>
              )}
              {["Moving"].includes(type) && moveSize?.length ? (
                <MoveSize
                  values={moveSize}
                  handleUpdateBookingObject={handleUpdateBookingObject}
                  moveSizeId={moveSizeId}
                />
              ) : (
                <></>
              )}
              {width < 600 && (
                <CTASection
                  type={type}
                  total={total}
                  distanceInKm={distanceInKm}
                  handleBookNow={handleBookNow}
                  handleScheduleForLater={handleScheduleForLater}
                  coupon={coupon}
                  handleUpdateBookingObject={handleUpdateBookingObject}
                />
              )}
            </div>
            {width > 600 && (
              <div className={css?.bookingRigthDiv}>
                <Map
                  pickupLocation={pickupLocation}
                  dropoffLocation={dropoffLocation}
                  stops={stops}
                  pickupLatitude={pickupLatitude}
                  pickupLongitude={pickupLongitude}
                  dropoffLatitude={dropoffLatitude}
                  dropoffLongitude={dropoffLongitude}
                  distanceInKm={distanceInKm}
                  durationInMins={durationInMins}
                  handleUpdateBookingObject={handleUpdateBookingObject}
                  height={total ? 216 : 70}
                />
                <div style={{ padding: 10, color: "#f2f2f2" }}>
                  <CTASection
                    type={type}
                    distanceInKm={distanceInKm}
                    total={total}
                    coupon={coupon}
                    handleUpdateBookingObject={handleUpdateBookingObject}
                    handleBookNow={handleBookNow}
                    handleScheduleForLater={handleScheduleForLater}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={css?.bookingBottomDiv}>
            <AppCards />
          </div>
        </div>
      ) : tripStatus === "Confirmation" ? (
        <div className={css?.BookingMainDiv}>
          <ConfirmDetails
            stops={stops}
            type={type}
            movePlaces={movePlaces}
            moveSize={moveSize}
            pickupLocation={pickupLocation}
            dropoffLocation={dropoffLocation}
            deliveryDate={deliveryDate}
            vehicle={vehicle}
            labourNeeded={labourNeeded}
            moveTypeId={moveTypeId}
            moveSizeId={moveSizeId}
            isDeliverNow={isDeliverNow}
            exactTime={exactTime}
          />
          <RecipentInfo
            errorObject={errorObject}
            pickUpPersonName={pickUpPersonName}
            pickUpPersonPhone={pickUpPersonPhone}
            pickUpInstructions={pickUpInstructions}
            stops={stops}
            dropoffLocation={dropoffLocation}
            dropOffPersonName={dropOffPersonName}
            dropOffPersonPhone={dropOffPersonPhone}
            dropOffInstructions={dropOffInstructions}
            setErrorObject={setErrorObject}
            handleUpdateBookingObject={handleUpdateBookingObject}
          />
          <div className={css?.confirmBottomDiv}>
            <div className={css?.confirmBottomLeftDiv}>
              <Upload
                id={id}
                bookingFiles={bookingFiles}
                handleUpdateBookingObject={handleUpdateBookingObject}
              />
            </div>
            <div className={css?.confirmBottomRightDiv}>
              <TotalConfirm
                type={type}
                bookingTotalModel={total}
                coupon={coupon}
                handleUpdateBookingObject={handleUpdateBookingObject}
                distanceInKm={distanceInKm}
              />
              <div className={css?.CTA_BTN} onClick={handleConfirmOrder}>
                Proceed
              </div>
              <div className={css?.CTA_BTN} onClick={handleEditOrder}>
                Edit Booking
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={css?.BookingMainDiv}>
          <PaymentScreen
            id={id}
            type={type}
            bookingTotalModel={total}
            distanceInKm={distanceInKm}
            statesToBookingObj={statesToBookingObj}
            handleUpdateBookingObject={handleUpdateBookingObject}
            userInfo={loginInfo}
            coupon={coupon}
            setShowLottie={setShowLottie}
            setShowPaymentMessage={setShowPaymentMessage}
          />
        </div>
      )}
    </div>
  );
};

export default Booking;
