import React from "react";
import css from "../../style/booking.module.css";
import TotalConfirm from "./TotalS1";

const CTASection = ({
  type,
  total,
  handleBookNow,
  handleScheduleForLater,
  distanceInKm,
  handleUpdateBookingObject,
  coupon,
}) => {
  return (
    <div className={css?.CTA_SECTION}>
      {total?.totalCharge && (
        <TotalConfirm
          type={type}
          distanceInKm={distanceInKm}
          total={total}
          handleUpdateBookingObject={handleUpdateBookingObject}
          coupon={coupon}
        />
      )}
      <div className={css?.bookingButtonsGroup}>
        {["Delivery", "Movers"].includes(type) ? (
          <div className={css?.CTA_BTN} onClick={() => handleBookNow(true)}>
            {type == "Delivery" ? "Deliver now" : "Book now"}
          </div>
        ) : (
          <></>
        )}
        <div
          className={css?.CTA_BTN}
          style={{ margin: "auto" }}
          onClick={handleScheduleForLater}
        >
          {/* Schedule a move */}
          {["Delivery", "Movers"].includes(type)
            ? "Schedule for later"
            : "Schedule a move"}
        </div>
      </div>
    </div>
  );
};

export default CTASection;
